import './App.css';
import * as PropTypes from "prop-types";
import {Routes, Route} from "react-router-dom";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import ProtectedRoute from "./components/Routing/ProtectedRoute";
import Video from "./pages/Video/Video";
import Result from "./pages/Result/Result";
import NotFound from "./pages/NotFound/NotFound";
import Deeplink from "./pages/Deeplink/Deeplink";
import Minero from "./pages/Login/Minero/Minero";
import Minero2 from "./pages/Login/Minero2/Minero2";
import FF from "./pages/Login/FF/FF";

Routes.propTypes = {children: PropTypes.node};

function App() {
    return (
        <Routes>
            <Route path="/login" element={<Login/>}/>
            <Route path="/login/minero" element={<Minero/>}/>
            <Route path="/login/minero2" element={<Minero2/>}/>
            <Route path="/login/ff" element={<FF/>}/>
            <Route path="/public/video/:slug" element={<Deeplink/>}/>
            <Route path="/" element={<ProtectedRoute/>}>
                <Route path="/" element={<Home/>}/>
                <Route path="/video/:slug" element={<Video/>}/>
                <Route path="/result" element={<Result/>}/>
            </Route>
            <Route path="*" element={<NotFound/>}/>

        </Routes>
    );
}

export default App;
