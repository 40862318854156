import React, {useEffect, useState} from 'react';
import {useAuth} from "../../../hooks/useAuth";
import Layout from "../../../components/Layout/Layout";
import classes from '../Login.module.scss';
import G from "../../../assets/G.svg";
import axios from "axios";
import authServices from "../../../services/authServices";
import GoogleLogin from "react-google-login";
import {gapi} from 'gapi-script';
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router-dom";


const Minero = () => {
        let location = useLocation();
        const navigate = useNavigate();
        let from = location.state?.from?.pathname || "/";
        const {login} = useAuth();
        const clientId = '725798464705-feiatgii01khof21sag2gd3in595eigh.apps.googleusercontent.com';

        useEffect(() => {
            gapi.load('client:auth2', initClient);

        }, []);

        const onSuccess = async (res) => {
            await axios.get("https://www.googleapis.com/oauth2/v1/userinfo?access_token=" + res.tokenObj.access_token).then(async response => {
                authServices.loginUser(response.data.family_name + " " + response.data.given_name, response.data.email, res.tokenObj.access_token).then(
                    await login({
                        token: res.tokenObj.access_token
                    })
                );
            })
        };

        const initClient = async () => {
            await gapi.client.init({
                clientId: clientId, scope: ''
            });
        };

        const onFailure = (err) => {
            console.log('failed:', err);
        };
        return (<Layout>
            <div className={"flex w-full items-center justify-center " + classes["background"]}>
                <div className="bg-white px-8 py-10 rounded-md md:w-[540px] mx-8">
                    <div className="text-center">
                        <h1 className="font-header font-black text-2xl text-black mb-1">GLOSTER VIDEO PORTAL</h1>
                        <p className={'pb-5 border-b-2 border-brandSecondary mb-5 md:mb-12 text-xs text-black font-montserratM text-black mt-2'}>Jelentkezz
                            be a
                            Google
                            fiókod segítségével!</p>
                        <div className={'flex flex-col gap-8 mb-5 md:mb-8 items-center'}>

                            <GoogleLogin
                                clientId={clientId}
                                render={renderProps => (
                                    <button className={'btn-login flex items-center justify-center gap-5'}
                                            onClick={renderProps.onClick} disabled={renderProps.disabled}><img src={G}
                                                                                                               alt="Google"/>Bejelentkezés{' '}
                                    </button>)}
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                cookiePolicy={'single_host_origin'}
                                isSignedIn={true}
                            />

                        </div>
                        <p className={'text-[10px] md:text-xs'}>A Gloster Video Portal egy zárt oldal, amely kizárólag
                            az arra
                            jogosultak hozzáférésével érhető el. Amennyiben szeretne hozzáférést, keresse fel
                            ügyfélszolgálatunkat.</p>
                    </div>

                </div>
            </div>
        </Layout>);
    }
;

export default Minero;
