import React, {useEffect, useState} from 'react';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {GoogleLogin} from '@react-oauth/google';

const Minero2 = () => {
    const clientId = '725798464705-feiatgii01khof21sag2gd3in595eigh.apps.googleusercontent.com';

    const responseGoogle = (response) => {
        console.log(response);
    }

    return (
        <div className="">
            <div className="">
                <GoogleOAuthProvider
                    clientId={clientId}
                >
                    <GoogleLogin
                        render={(renderProps) => (
                            <button
                                type="button"
                                className=""
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                            >
                                Sign in with google
                            </button>
                        )}
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy="single_host_origin"
                    />
                </GoogleOAuthProvider>
            </div>
        </div>
    )
}

export default Minero2;
